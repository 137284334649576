import React, { useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import PageLoader from "../../components/PageLoader";
import moment from "moment";
import styled from "@emotion/styled";
import usePagination from "@mui/material/usePagination/usePagination";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface Workspace {
  id: number;
  name: string;
  email: string;
  company_name: string;
  country: string;
  where_super_admin: string;
  join_date: string;
}

interface propsTypes {
  error?: string;
}

const sampleData: Workspace[] = [
  {
    id: 1,
    name: "Demi Wilkinson",
    email: "demi@untitledui.com",
    company_name: "Australia",
    country: "Australia",
    where_super_admin: "Australia",
    join_date: "12/02/2022",
  },
  {
    id: 2,
    name: "Candice Wu",
    email: "candice@untitledui.com",
    company_name: "Australia",
    country: "Australia",
    where_super_admin: "Australia",
    join_date: "12/01/2022",
  },
  {
    id: 3,
    name: "Natali Craig",
    email: "natali@untitledui.com",
    company_name: "Australia",
    country: "Australia",
    where_super_admin: "Australia",
    join_date: "12/02/2022",
  },
  {
    id: 4,
    name: "Drew Cano",
    email: "drew@untitledui.com",
    company_name: "Australia",
    country: "Australia",
    where_super_admin: "Australia",
    join_date: "12/02/2022",
  },
  {
    id: 5,
    name: "Orlando Diggs",
    email: "drlando@untitledui.com",
    company_name: "Australia",
    country: "Australia",
    where_super_admin: "Australia",
    join_date: "12/02/2022",
  },
  {
    id: 6,
    name: "Andi Lane",
    email: "andi@untitledui.com",
    company_name: "Australia",
    country: "Australia",
    where_super_admin: "Australia",
    join_date: "12/02/2022",
  },
  {
    id: 7,
    name: "Kate Morrison",
    email: "kate@untitledui.com",
    company_name: "Australia",
    country: "Australia",
    where_super_admin: "Australia",
    join_date: "12/02/2022",
  },
  {
    id: 8,
    name: "Kate Morrison",
    email: "kate@untitledui.com",
    company_name: "Australia",
    country: "Australia",
    where_super_admin: "Australia",
    join_date: "12/02/2022",
  },
  {
    id: 9,
    name: "Kate Morrison",
    email: "kate@untitledui.com",
    company_name: "Australia",
    country: "Australia",
    where_super_admin: "Australia",
    join_date: "12/02/2022",
  },
  {
    id: 10,
    name: "Kate Morrison",
    email: "kate@untitledui.com",
    company_name: "Australia",
    country: "Australia",
    where_super_admin: "Australia",
    join_date: "12/02/2022",
  },
];

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Workspace: React.FC<propsTypes> = () => {
  const [loading, setloading] = useState<boolean>(false);
  const [isToggled, setIsToggled] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const handleCheckboxChange = (taskId: number) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(taskId)) {
        return prevSelectedRows.filter((id) => id !== taskId);
      } else {
        return [...prevSelectedRows, taskId];
      }
    });
  };

  const { items } = usePagination({
    count: 10,
  });

  const handleToggle = () => {
    setSelectedRows(isToggled ? [] : sampleData.map((workflow) => workflow.id));
    setIsToggled(!isToggled);
  };

  return (
    <>
      <AdminNavbar />

      {loading ? (
        <PageLoader />
      ) : (
        <>
          <div className="inner-scroller task-page" style={{minHeight: 640}}>
            <button
              type="button"
              className="btn btn-dark"
              style={{ marginBottom: "37px" }}
            >
              Download all
            </button>
            <div className="table-responsive" style={{ borderRadius: "8px" }}>
              <table className="task-table table">
                <thead>
                  <tr>
                    <th>
                      <div className="form-check d-flex align-items-center mb-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="name1"
                          onChange={handleToggle}
                          checked={isToggled}
                        />

                        <label className="form-check-label" htmlFor="name1">
                          Name
                        </label>
                      </div>
                    </th>
                    <th className="text-center">Email address</th>
                    <th className="text-center">Company Name</th>
                    <th className="text-center">Country</th>
                    <th className="text-center">
                      Number of Workspace
                      <br />
                      where super admin
                    </th>
                    <th className="text-center">Join Date</th>
                  </tr>
                </thead>
                <tbody>
                  {sampleData && sampleData.length > 0
                    ? sampleData.map((workspace: Workspace) => (
                        <tr key={workspace.id}>
                          <td>
                            <div className="form-check d-flex align-items-center mb-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="name3"
                                checked={selectedRows.includes(workspace.id)}
                                onChange={() =>
                                  handleCheckboxChange(workspace.id)
                                }
                              />

                              <label
                                className="form-check-label text-black"
                                htmlFor="name3"
                              >
                                {workspace.name}
                              </label>
                            </div>
                          </td>
                          <td className="text-center">{workspace.email}</td>
                          <td className="text-center">
                            {workspace.company_name}
                          </td>
                          <td className="text-center">{workspace.country}</td>
                          <td className="text-center">
                            {workspace.where_super_admin}
                          </td>
                          <td className="text-black text-center">
                            {moment(workspace.join_date).format("DD/MM/YYYY")}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "0px 20px",
            }}
            className="mt-4"
          >
            <button
              className="btn btn-light"
              type="button"
              onClick={items[0].onClick}
              disabled={items[0].disabled}
            >
              <ArrowBackIcon
                style={{
                  margin: "0px 10px",
                }}
              />
              {"Previous"}
            </button>
            <List>
              {items.map(({ page, type, selected, ...item }, index) => {
                let children = null;

                if (type === "start-ellipsis" || type === "end-ellipsis") {
                  children = "…";
                } else if (type === "page") {
                  children = (
                    <button
                      type="button"
                      style={{
                        fontWeight: selected ? "bold" : undefined,
                        background: selected ? "#1018281A" : "#fff",
                        padding: "12px",
                        borderRadius: "8px",
                        fontSize: "14px",
                      }}
                      {...item}
                    >
                      {page}
                    </button>
                  );
                }
                return (
                  <li key={index} style={{ margin: "0px 5px" }}>
                    {children}
                  </li>
                );
              })}
            </List>
            <button
              className="btn btn-dark float-right"
              type="button"
              onClick={items[items.length - 1].onClick}
              disabled={items[items.length - 1].disabled}
            >
              {"Next"}
              <ArrowForwardIcon
                style={{
                  margin: "0px 10px",
                }}
              />
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Workspace;
