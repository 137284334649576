import React, { useCallback, useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import avatarImg from "../assets/img/avatar.jpg";
import Select from "../../components/Select";
import Modal from "../../components/Modal";
import { DatePicker } from "antd";
import { WorkflowTeamAdd } from "../../components/WorkFlowTeam/WorkFlowTeam";
import { SERVER_BASE_URL } from "../../constants/urles";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment, { Moment, isDate } from "moment";
import dayjs, { Dayjs } from "dayjs";
import UserSelect from "../../components/UserSelect";
import AlertModal from "../../components/Alert";
import * as XLSX from "xlsx";
import DynamicSelect from "../../components/DynamicSelect";
import UseUserTeamInfo from "../../components/FetchRoleAndTeam";
import PageLoader from "../../components/PageLoader";
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
interface WorkflowProps {}
interface taskInfoInter {
  name: string;
}
interface User {
  firstName: string;
  lastName: string;
}
interface Participant {
  participates: any;
  id: number;
  userId: number | null;
  email: string;
  roleId: number | null;
  isInvited: boolean;
  user: {
    firstName: string;
    lastName: string;
    email: string;
  } | null;
  roles: any[]; // You may want to define a proper type for roles
}
interface Task {
  id: number;
  name: string;
  workspaceId: number;
  dueDate: string | null;
  userId: number;
  teamId: number | null;
  allocate: number | null;
  taskNum: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  user: User;
  participates: any; // You may want to define a proper type for participates
}

interface ApiResponse {
  name: string;
  data: Task[];
}

interface selectInter {
  value: number;
  label: string;
}
interface TeamMember {
  presentPassword: string;
  email: string;
  password: string;
  permission: number;
}
const Workflow: React.FC<WorkflowProps> = () => {
  const [selectedTeam, setSelectedTeam] = useState<number>(0);
  const [workflos, setworkflos] = useState<Task[]>([]);
  const [filteredWorkflows, setfilteredWorkflows] = useState<Task[]>([]);
  const [selectedParticipents, setSelectedParticipents] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [taskDelete, settaskDelete] = useState<boolean>(false);
  const [showTeamMemberAddModal, setShowTeamMemberAddModal] =
    useState<boolean>(false);
  const [alertShow, setalertShow] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);
  const [allParticipants, setAllParticipants] = useState<selectInter[]>([]);
  const [message, setmessage] = useState<string>("");
  const [typeOfAlert, settypeOfAlert] = useState<string>("");
  const [newTeamMemberState, setNewTeamMemberState] = useState<TeamMember>({
    presentPassword: "",
    email: "",
    password: "",
    permission: 0,
  });

  const { token } = useSelector((state: any) => state.userToken);
  const { userId } = useSelector((state: any) => state.userid);
  const params = useParams();
  const [showInviteTeamModal, setShowInviteTeamModal] =
    useState<boolean>(false);

  const handlePasswordInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewTeamMemberState({
        ...newTeamMemberState,
        password: e.target.value,
      });
    },
    [newTeamMemberState]
  );

  const handleEmailInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewTeamMemberState({
        ...newTeamMemberState,
        email: e.target.value,
      });
    },
    [newTeamMemberState]
  );

  const handlePresentPasswordInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewTeamMemberState({
        ...newTeamMemberState,
        presentPassword: e.target.value,
      });
    },
    [newTeamMemberState]
  );

  const handleNewTeamMember = useCallback(() => {}, []);

  useEffect(() => {}, []);

  return (
    <>
      <AdminNavbar />
      <div className="top-action-bar style-2 d-sm-flex flex-wrap align-items-center">
        <div className="d-flex align-items-center gap-5">
          <button
            type="button"
            className="part-team-add-btn__v2"
            onClick={() => setShowTeamMemberAddModal(true)}
          >
            <span>
              <i className="icon-plus"></i>
            </span>
            Add Team Member
          </button>
        </div>
      </div>
      <div
        style={{
          margin: "20px",
        }}
      >
        <div
          style={{
            padding: "16px",
            background: "#F9FAFB",
            width: "30%",
            borderBottom: "1px #EAECF0 solid",
          }}
        >
          Team Member
        </div>
        <List
          sx={{ width: "100%", maxWidth: 700, bgcolor: "background.paper" }}
        >
          {[0, 1, 2, 3].map((value) => {
            const labelId = `checkbox-list-label-${value}`;
            return (
              <ListItem
                key={value}
                secondaryAction={
                  <IconButton edge="end" aria-label="comments"></IconButton>
                }
                style={{ width: "100%" }}
                disablePadding
              >
                <ListItemButton role={undefined} dense>
                  <ListItemIcon>
                    <img
                      style={{
                        borderRadius: "200px",
                        width: "32px",
                        height: "32px",
                      }}
                      alt="Avatar"
                      src="https://gravatar.com/avatar/4ac2aa1acc625d54512378128cceb250?s=400&d=robohash&r=x"
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId}>
                    {value === 0 ? (
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-column">
                          <span>Candice Wu</span>
                          <span
                            style={{
                              color: "#667085",
                            }}
                          >
                            candice@untitledui.com
                          </span>
                        </div>
                        <div
                          style={{
                            width: "130px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              color: "#027A48",
                              background: "#ECFDF3",
                              borderRadius: "16px",
                              padding: "3px",
                              margin: "0px 15px",
                            }}
                          >
                            Super Admin
                          </span>
                        </div>
                        <span
                          style={{
                            color: "#B42318",
                          }}
                        >
                          Remove
                        </span>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center justify-content-space-between">
                        <div className="d-flex flex-column">
                          <span>Candice Wu</span>
                          <span
                            style={{
                              color: "#667085",
                            }}
                          >
                            candice@untitledui.com
                          </span>
                        </div>
                        <div
                          style={{
                            width: "130px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              color: "#175CD3",
                              background: "#EFF8FF  ",
                              borderRadius: "16px",
                              padding: "3px",
                              margin: "0px 15px",
                            }}
                          >
                            Member
                          </span>
                        </div>
                        <span
                          style={{
                            color: "#B42318",
                          }}
                        >
                          Remove
                        </span>
                        <span
                          style={{
                            color: "rgba(23, 23, 23, 0.60)",
                            marginLeft: "15px",
                          }}
                        >
                          Invited
                        </span>
                      </div>
                    )}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </div>
      {loading ? <PageLoader /> : <div></div>}
      <Modal
        show={showTeamMemberAddModal}
        onSave={handleNewTeamMember}
        onSaveBtnText="Invite"
        onCancel={() => {
          setShowTeamMemberAddModal(false);
        }}
      >
        <h5 className="modal-title">New Team Member</h5>
        <div className="form-group">
          <label htmlFor="" className="form-label color-2">
            Present Password
          </label>
          <input
            type="password"
            name="presentPassword"
            onChange={handlePresentPasswordInput}
            className={`form-control`}
            value={newTeamMemberState.presentPassword}
          />
        </div>
        <div className="form-group">
          <label htmlFor="" className="form-label color-2">
            Email
          </label>
          <input
            type="email"
            name="presentPassword"
            onChange={handleEmailInput}
            className={`form-control`}
            value={newTeamMemberState.email}
          />
        </div>
        <div className="form-group">
          <label htmlFor="" className="form-label color-2">
            Password
          </label>
          <input
            type="password"
            name="presentPassword"
            onChange={handlePasswordInput}
            className={`form-control`}
            value={newTeamMemberState.password}
          />
        </div>
        <div className="form-group">
          <Select
            placeholder="Permission"
            defaultSelectedValue={selectedStatus}
            options={[
              { value: 1, label: "Supser User" },
              { value: 2, label: "Member" },
            ]}
            onChange={(e) => setSelectedStatus(e)}
          />
        </div>
      </Modal>
    </>
  );
};

export default Workflow;